import React from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next'
import SEO from 'components/SEO'
import ContentContainer from 'components/ContentContainer'
import Text from 'components/Text'

const NotFoundPage = () => {
  const {t} = useI18next()
  return (
    <ContentContainer withMb withMt>
      <SEO title={t('pages.404.seoTitle')} description={t('pages.404.seoDescription')} />
      <Text component="h1" size="titleMd" content={t('pages.404.title')} />
      <Text content={t('pages.404.text')} size="md" />
    </ContentContainer>
  )
}

export default NotFoundPage
